import * as elements from './elements'

let scrolling_is_disabled = false
let scrollY = 0

export class SiteContent {

  constructor() {}

  static get scrollY() {
    return scrollY
  }

  static get scrolling_is_disabled() {
    return scrolling_is_disabled
  }

  static disable_scrolling() {
    if (scrolling_is_disabled === false) {
      scrollY = window.scrollY
      elements.EL_SITE_CONTENT.style.top = `-${scrollY}px`
      elements.EL_SITE_CONTENT.classList.add('_disable_scrolling')
      scrolling_is_disabled = true
    }
  }

  static enable_scrolling() {
    if (scrolling_is_disabled === true) {
      elements.EL_SITE_CONTENT.style.top = '0px'
      elements.EL_SITE_CONTENT.classList.remove('_disable_scrolling')
      window.window.scrollTo(0, scrollY)
      scrolling_is_disabled = false
    }
  }

}