import {
  UITextArea,
} from '@nekobird/rocket'

import {
  Site,
} from './site'

import {
  addContactForm,
} from './form_handler'

let site = new Site()

const el_textarea = document.getElementById('textarea')
let ui_textarea = new UITextArea(el_textarea)

// Handle contact form stuff.
addContactForm({
  location: "parking",
  form_id: "omnilander_contact_form",
  success_message: "Your message was sent successfully. We will be in touch with you as soon as possible.",
  fields: {
    "email": {
      validate: true,
      type: 'email',
    },
    message: {
      validate: true,
      type: 'string',
    },
    "captcha-hidden": {
      validate: true,
      type: 'recaptcha'
    }
  }
})