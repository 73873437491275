"use strict";

export function addContactForm(config) {
	var form = document.getElementById(config.form_id);

	for (var key in config.fields) {
		var field_config = config.fields[key];

		// resolve type if required
		if (!field_config.hasOwnProperty('type')) {
			field_config.type = 'string';
		}

		// resolve validate if required 
		if (!field_config.hasOwnProperty('validate')) {
			field_config.validate = false;
		}

		// check and cache element
		var el = form.querySelector('[name="' + key + '"]');
		if (el === null) {
			console.error("[FORM] No form field found with name '" + key + "'");
			continue;
		}
		field_config.el = el;

		if (field_config.validate) {
			// check and cache label element
			var label_selector = 'label#label_' + key;
			var label = form.querySelector(label_selector);
			if (label === null) {
				console.error("[FORM] Field with name '" + key + "' is marked as validate but no label element was found with selector '" + label_selector + "'");
				continue;
			}
			field_config.el_label = label;

			// resolve validation function if required
			if (!field_config.hasOwnProperty('validation_function')) {
				var validation_func = null;
				if (field_config.type == 'email') {
					validation_func = function (el) {
						var regex = /\S+@\S+\.\S+/;
						return regex.test(el.value.trim());
					};
				} else if (field_config.type == 'recaptcha') {
					validation_func = function (el) {
						return grecaptcha.getResponse().trim().length > 0;
					};
				} else if (field_config.type == 'string') {
					validation_func = function (el) {
						return el.value.trim() != '';
					};
				} else {
					console.error("[FORM] Invalid type '" + field_config.type + "' specified for field with name '" + key + "'");
				}
				field_config.validation_function = validation_func;
			}
		}
	}
	form.onsubmit = function (event) {
		event.preventDefault()
		console.log("submit")
		// validate the form
		var passed_validation = true; {
			for (var key in config.fields) {
				var field = config.fields[key];
				if (field.validate) {
					if (field.validation_function(field.el)) {
						field.el_label.classList.add("hidden");
					} else {
						field.el_label.classList.remove("hidden");
						passed_validation = false;
					}
				}
			}
		}

		// submit form if we passed validation
		if (passed_validation) {
			form.querySelector("button#submit").classList.add('hidden');
			// form.querySelector("span#btn_submitting").classList.remove('hidden');
			// form.querySelector("span#btn_error").classList.add('hidden');
			// form.querySelector("span#btn_success").classList.add('hidden');

			//setTimeout(function() {

			var url = '//postform.donkeymob.com/v1/contact?location=' + config.location;

			var data = null; {
				data = [];
				for (var key in config.fields) {
					if (config.fields[key].type == 'recaptcha') {
						data.push('g-recaptcha-response=' + encodeURIComponent(grecaptcha.getResponse()));
					} else {
						data.push(key + '=' + encodeURIComponent(config.fields[key].el.value));
					}
				}
				data = data.join('&');
			}

			var request = new XMLHttpRequest();
			request.open("POST", url, true);
			request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
			request.onreadystatechange = function () {
				if (request.readyState == 4) {
					console.log(request.responseText);

					if (request.status == 200) {
						// // show correct button 
						// form.querySelector("span#btn_submitting").classList.add('hidden');
						// form.querySelector("span#btn_success").classList.remove('hidden');

						// display message
						form.querySelector('#submit_message').innerHTML = config.success_message;
						form.querySelector('#submit_message').classList.remove('hidden');
						form.querySelector('#submit_message').classList.add('_success');
					} else {
						// // show correct button 
						// form.querySelector("span#btn_submitting").classList.add('hidden');
						// form.querySelector("span#btn_error").classList.remove('hidden');

						// display message
						form.querySelector('#submit_message').innerHTML = "There is an error in your submission";
						form.querySelector('#submit_message').classList.remove('hidden');
						form.querySelector('#submit_message').classList.add('_error');
					}
				}
			};
			request.send(data);

			//}, 5000);
		}

		return false;
	}
}