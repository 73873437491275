import {
  DOMUtil,
} from '@nekobird/rocket'

import {
  SiteContent,
} from './site_content'

import * as elements from './elements'

export class Site {

  constructor() {
    this.modal_contact_is_open = false
    this.initialize_handlers()
    this.start_listening()
  }

  open_modal_contact() {
    if (this.modal_contact_is_open === false) {
      elements.EL_MODAL_CONTACT.style.display = 'block'

      // Activate overlay.
      elements.EL_SITE_OVERLAY.classList.add('_active')
      elements.EL_SITE_OVERLAY.classList.remove('_animate_out')
      elements.EL_SITE_OVERLAY.classList.add('_animate_in')

      // Activate modal.
      elements.EL_MODAL_CONTACT.classList.add('_active')
      elements.EL_MODAL_CONTACT.classList.remove('_animate_out')
      elements.EL_MODAL_CONTACT.classList.add('_animate_in')

      setTimeout(() => {
        this.modal_contact_is_open = true
        SiteContent.disable_scrolling()
      }, 100)
    }
  }

  close_modal_contact() {
    if (this.modal_contact_is_open === true) {

      // Animate out modal.
      elements.EL_MODAL_CONTACT.classList.remove('_animate_in')
      elements.EL_MODAL_CONTACT.classList.add('_animate_out')

      // Wait until animation is completed.
      setTimeout(() => {
        elements.EL_MODAL_CONTACT.classList.remove('_active')

        // Animate out overlay.
        elements.EL_SITE_OVERLAY.classList.remove('_animate_in')
        elements.EL_SITE_OVERLAY.classList.add('_animate_out')

        // Wait until animation is completed.
        setTimeout(() => {
          elements.EL_SITE_OVERLAY.classList.remove('_active')
          this.modal_contact_is_open = false
          SiteContent.enable_scrolling()
        }, 200)
      }, 200)
    }
  }

  initialize_handlers() {
    this._click_modal_contact_open_handler = event => {
      event.preventDefault()
      this.open_modal_contact()
    }
    this._click_modal_contact_close_handler = event => {
      event.preventDefault()
      this.close_modal_contact()
    }
    this._click_outside_modal_contact_handler = event => {
      if (
        this.modal_contact_is_open === true &&
        !DOMUtil.hasAncestor(
          event.target, elements.EL_MODAL_CONTACT
        )
      ) {
        event.preventDefault()
        this.close_modal_contact()
      }
    }
    this._keydown_modal_contact_handler = event => {
      if (
        this.modal_contact_is_open === true &&
        event.keyCode === 27
      ) {
        event.preventDefault()
        this.close_modal_contact()
      }
    }
  }

  start_listening() {
    elements.ELS_JS_MODAL_CONTACT_OPEN.forEach(element => {
      element.addEventListener('click', this._click_modal_contact_open_handler)
    })
    elements.ELS_JS_MODAL_CONTACT_CLOSE.forEach(element => {
      element.addEventListener('click', this._click_modal_contact_close_handler)
    })
    window.addEventListener('click', this._click_outside_modal_contact_handler)
    window.addEventListener('keydown', this._keydown_modal_contact_handler)
  }

}